import React, { useContext } from "react";
import LangaugeContext from "../contexts/LanguageContext";
import HomeSection from "../components/HomeSection";
import OurPlans from "../components/OurPlans";
import Advantages from "../components/Advantages";

// import Swiper core and required modules
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
const swiperImgs = require("../data/images.json")["medical_network_images"];

// install Swiper modules
SwiperCore.use([Pagination, Navigation, Autoplay]);

export default function HomePage() {
  const lang = useContext(LangaugeContext);

  return (
    <div className="homePage">
      <HomeSection />
      <OurPlans />
      <Advantages />
      <section
        style={{ flexDirection: "column" }}
        className="flex-container-global our-partners"
      >
        <h1 style={{ textAlign: "center", fontSize: "2em" }}>
          {lang === "en" ? "OUR PARTNERS" : "بعض شركائنا"}
        </h1>
        <Swiper
          breakpoints={{
            1500: {
              width: 1500,
              slidesPerView: 3,
            },
            900: {
              width: 900,
              slidesPerView: 2,
            },
          }}
          slidesPerView={1}
          spaceBetween={5}
          loop={true}
          loopFillGroupWithBlank={false}
          autoplay={{
            delay: 4000,
          }}
          className="mySwiper"
        >
          {swiperImgs.map((img, i) => (
            <SwiperSlide className="flex" key={i}>
              <div className="swiper-img-container">
                <img src={img} alt="" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    </div>
  );
}
