import React, {
  useContext,
  // useEffect,
  useState,
} from "react";
import "./ContactUs.css";
import LanguageContext from "../contexts/LanguageContext";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import { Fade } from "react-awesome-reveal";
const imgBanner = require("../data/images.json")["banner_contact_us"];

export default function ContactUs() {
  const lang = useContext(LanguageContext);
  const direction = lang === "ar" ? "rtl" : "ltr";
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    // http://localhost:5000/api/contact
    // fetch('http://localhost:5000/send-email', {
    fetch("https://omegacare-server.testing.ahmedkira.com/send-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: name,
        email: email,
        subject: "contact",
        type: "contact",
        message: message,
        phone: phone,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
      });
  };
  return (
    <main className="contactUs">
      <section
        className="banner"
        style={{
          backgroundImage: `url(${imgBanner})`,
          backgroundSize: "cover",
          direction: direction,
          minHeight: "70vh",
        }}
      ></section>
      <section className="content">
        <div className="form-container" style={{ direction: direction }}>
          <h1 className="title">
            {lang === "en" ? "Contact Us" : "تواصل معنا"}
          </h1>
          <p className="subtitle">
            {lang === "en"
              ? "Contact us and we'll get a representitive to get back to you as soon as possible!"
              : "تواصل معنا وسيرد عليك احد متخصصينا في اقرب وقت!"}
          </p>
          <p>
            {lang === "en" ? "Marked" : "ما عليه علامة "}{" "}
            <span style={{ color: "red" }}>*</span>{" "}
            {lang === "en" ? " is required" : "هو اجباري "}.
          </p>
          <form className="contactForm" onSubmit={handleSubmit}>
            <TextField
              className="input"
              type="text"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder={lang === "en" ? "Name" : "الاسم"}
              required
              label={lang === "en" ? "Name" : "الاسم"}
            />
            <TextField
              className="input"
              type="text"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder={lang === "en" ? "Phone" : "الهاتف"}
              required
              label={lang === "en" ? "Phone" : "الهاتف"}
            />
            <TextField
              className="input"
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={lang === "en" ? "Email" : "البريد الالكتروني"}
              required
              label={lang === "en" ? "Email" : "البريد الالكتروني"}
            />
            <TextField
              className="input"
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder={lang === "en" ? "Message" : "الرسالة"}
              required
              label={lang === "en" ? "Message" : "الرسالة"}
              multiline
            />
            <Button variant="contained" type="submit" className="submit-btn">
              {lang === "en" ? "Send" : "ارسال"}
            </Button>
          </form>
        </div>
        <Fade duration={1000}>
          <div className="other-means" style={{ direction: direction }}>
            <h2>
              {lang === "en" ? "Other means of contact" : "طرق اخرى للتواصل"}
            </h2>
            <p>
              {lang === "en" ? "Address" : "العنوان"}: 
              <span dir="rtl">
                4 أ / 2 شارع سيد ندا أمام بترو أمير – اللاسلكي – المعادي الجديدة
                – القاهرة
              </span>
            </p>
            <p>{lang === "en" ? "Hotline" : "الخط الساخن"}: 15924</p>
            <p>
              {lang === "en" ? " E-Mail" : "البريد الالكتروني"}:
              cs@omegacare-med.net
            </p>
          </div>
        </Fade>
      </section>
    </main>
  );
}
