import React, { useContext } from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import LanguageContext from "../contexts/LanguageContext";
const logo = require("../data/images.json")["new_logo_2"];
const socialLinks = require("../data/data.json")["links"];

const links = {
  en: ["HOME", "ABOUT US", "MEDICAL NETWORK", "NEWSLETTER", "CONTACT US"],
  ar: ["الرئيسية", "من نحن", "الشبكة الطبية", "الاخبار", "تواصل معنا"],
  links: ["/", "/about", "/medical-network", "/newsletter", "/contact-us"],
};

export default function Footer() {
  const lang = useContext(LanguageContext);

  const backToTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  return (
    <footer className="footer">
      <div className="backToTop">
        <p onClick={backToTop}>
          {lang === "en" ? "Back to top" : "اذهب إلى الأعلى"}
        </p>
      </div>
      <div className="footerContent">
        <div className="footerLogo">
          <Link to="/">
            <img src={logo} alt="logo" className="logo" onClick={backToTop} />
          </Link>
        </div>
        <div className="footerLinks">
          <Link to="/" onClick={backToTop}>
            {links[lang][0]}
          </Link>
          <Link to="/about" onClick={backToTop}>
            {links[lang][1]}
          </Link>
          <Link to="/medical-network" onClick={backToTop}>
            {links[lang][2]}
          </Link>
          <Link to="/newsletter" onClick={backToTop}>
            {links[lang][3]}
          </Link>
          <Link to="/contact-us" onClick={backToTop}>
            {links[lang][4]}
          </Link>
        </div>
        <div className="footerSocial">
          <p>{lang === "en" ? "Follow Us" : "تابعنا"}</p>
          <ul>
            <li>
              <a href={socialLinks.facebook} target="_blank" rel="noreferrer">
                <i className="fab fa-facebook"></i>
              </a>
            </li>
            {/* <li>
              <a href={socialLinks.twitter}>
                <i className="fab fa-twitter"></i>
              </a>
            </li>
            <li>
              <a href={socialLinks.instagram}>
                <i className="fab fa-instagram"></i>
              </a>
            </li>
            <li>
              <a href={socialLinks.facebook}>
                <i className="fab fa-youtube"></i>
              </a>
            </li> */}
          </ul>
          <a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.omegacareinsurance.med">{lang === "en" ? "Download our app" : "حمل تطبيقنا"}</a>
        </div>
      </div>
      <p className="rights">
        {lang === "en"
          ? "© 2021 OmegaCare, Inc. All rights reserved"
          : "© 2021 اوميجاكير، جميع الحقوق محفوظة"}
      </p>
    </footer>
  );
}
