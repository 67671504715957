import React, { useContext } from "react";
import "./MedicalNetwork.css";
import MNTable from "../components/MNTable";
import LanguageContext from "../contexts/LanguageContext";

const imgBanner = require("../data/images.json")["banner_medical_network"];

export default function MedicalNetwork() {
  const lang = useContext(LanguageContext);
  const direction = lang === "ar" ? "rtl" : "ltr";
  return (
    <main className="medicalNetwork" style={{direction: direction}}>
      <section
        className="banner"
        style={{
          backgroundImage: `url(${imgBanner})`,
          backgroundSize: "cover",
          minHeight: "80vh",
        }}
      >
        <div className="container">
          <h1 className="title">
            {lang === "en" ? "Medical Network" : "الشبكة الطبية"}
          </h1>
          <p className="">
            {lang === "en"
              ? "A wide variety of medical services spans across 27 governorates"
              : "تتوسط الخدمات الطبية المتنوعة عبر 27 محافظة"}
          </p>
        </div>
      </section>
      <section className="content">
        <div className="table-section">
          <h1 style={{ direction: direction }}>
            {lang === "en" ? "Our Medical Network" : "شبكتنا الطبية"}
          </h1>

          {/* <MedicalNetworkTable lang="ar" /> */}
          <MNTable />
        </div>
      </section>
    </main>
  );
}
