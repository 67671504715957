import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// components
import Header from "./components/Header";
import Footer from "./components/Footer";

// routes
import HomePage from "./routes/HomePage";
import MedicalNetwork from "./routes/MedicalNetwork";
import ErrorPage from "./routes/ErrorPage.js";
import AboutUs from "./routes/AboutUs";
import ContactUs from "./routes/ContactUs";
import Newsletter from "./routes/Newsletter";
import Button from "@mui/material/Button";

//language context
import LanguageContext from "./contexts/LanguageContext";

export default function App() {
  const [lang, setLanguage] = useState("en");
  const newLang = localStorage.getItem("lang");
  if (newLang && newLang !== lang) {
    setLanguage(newLang);
  }

  return (
    <Router>
      <LanguageContext.Provider value={lang}>
        <Header setLanguage={setLanguage} />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="medical-network" element={<MedicalNetwork />} />
          <Route path="about" element={<AboutUs />} />
          <Route path="newsletter" element={<Newsletter />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="*" element={<ErrorPage error={404} />} />
        </Routes>
        <Footer />
      </LanguageContext.Provider>
    </Router>
  );
}
