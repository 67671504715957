import { useEffect, useState } from "react";

export default function useFetch(url) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    console.log("fetching ", url);
    setLoading(true);

    (async()=>{
      try {
        const response = await (await fetch(url)).json();
        setData(response);
      } catch (err) {
        setError(err);
        console.log(err);
      } finally {
        setLoading(false);
      }
    })();

  }, [url]);

  return { data, loading, error };
}
