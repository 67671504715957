import React, { useState, useContext, useEffect } from "react";
import "./Newsletter.css";
import Post from "../components/Post";
import useFetch from "../hooks/useFetch";
import Loading from "../components/Loading";
import Button from "@mui/material/Button";

import LanguageContext from "../contexts/LanguageContext";

const URL = "https://omegacare-server.testing.ahmedkira.com/news?limit=1";
const imgBanner = require("../data/images.json")["banner_newsletter"];



export default function Newsletter() {
  const lang = useContext(LanguageContext);

  const [offset, setOffset] = useState(0);
  const [url, setUrl] = useState(`${URL}&offset=${offset}`);
  const [posts, setPosts] = useState([]);
  let { data, error, loading } = useFetch(url);

  useEffect(() => {
    if (data && data.length !== 0) {
      let t = posts;
      data.data.forEach((post) => {
        t.push(post);
      });
      setPosts(t);
    }
  }, [data]);

  useEffect(() => {
    setUrl(`${URL}&offset=${offset}`);
  }, [offset]);

  const loadMore = () => {
    setOffset(offset + 1);
  };

  return (
    <main
      className="newsletter"
      style={{
        minHeight: "80vh",
        direction: lang === "ar" ? "rtl" : "ltr",
        padding: `calc(var(--header-height) + 1em) 0px`,
        width: "100vw",
      }}
    >
      <section
        className="banner"
        style={{
          backgroundImage: `url(${imgBanner})`,
          backgroundSize: "cover",
          minHeight: "80vh",
        }}
      >
        <div className="container">
          <h1 className="title">
            {lang === "en" ? "Newsletter" : " الأخبار"}
          </h1>
          <p className="">
            {lang === "en"
              ? "A live coverage of our news"
              : "تغطية حية لكل ما يخص شركة اويجا كير "}
          </p>
        </div>
      </section>
      <div className="posts">
        {loading && (
          <Loading
            text={lang === "en" ? "Loading news" : "جاري تحميل الأخبار"}
          />
        )}
        {error && (
          <div>
            <br />
            <br />

            <p>
              {
                "Failed to load some or all data, please check your internet connection and refresh this page."
              }
            </p>
            <br />
            <p>
              If you believe this to be a bug, please contact
              bugs@omegacare-med.net
            </p>
            <br />
            <br />
            <br />
            <p dir="rtl">
              {
                "لم يتم تحميل بعض أو كل المعلومات، برجاء التأكد من أتصالك بالأنترنت ثم إعادة تحميل الصفحة."
              }
            </p>
            <br />
            <p dir="rtl">
              إن كنت متأكد من وجود مشكلة فالموقع، برجاء التواصل معنا على
              bugs@omegacare-med.net.
            </p>
            <br />
          </div>
        )}
        {posts &&
          posts.map((post, i) => {
            return <Post key={i} data={post} />;
          })}
      </div>
      <div className="bottomSection">
        {!(offset > posts.length - 1) && (
          <Button variant="contained" className="btn" onClick={loadMore}>
            {lang === "en" ? "Load more" : "حمل المزيد"}
          </Button>
        )}
      </div>
    </main>
  );
}
