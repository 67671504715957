import React, { useEffect, useState, useContext } from "react";
import useFetch from "../hooks/useFetch";
import Button from "@mui/material/Button";
import "./MNTable.css";
import Loading from "./Loading";
import SearchBar from "./SearchBar";
import MNTableFilters from "./MNTableFilters";
import LanguageContext from "../contexts/LanguageContext";
import Pagination from "@mui/material/Pagination";

export default function MNTable() {
  const PAGE_SIZE = 10;
  const URL = `https://omegacare-server.testing.ahmedkira.com/medical-network?limit=${PAGE_SIZE}`;
  const DISTINCTS_URL = `https://omegacare-server.testing.ahmedkira.com/get-all-distinct`;
  // const URL = `http://localhost:5000/medical-network?limit=${PAGE_SIZE}`;
  // const DISTINCTS_URL = `http://localhost:5000/get-all-distinct`;
  let lang = useContext(LanguageContext);
  const [url, setUrl] = useState(`${URL}&offset=0&&`);
  const [data, setData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [orderBy, setOrderBy] = useState("governorate");
  const [sortDir, setSortDir] = useState(1);
  const [filtersString, setFiltersString] = useState("");
  const [queryString, setQueryString] = useState("");
  let { data: rawData, error, loading } = useFetch(url);
  let {
    data: distincts,
    error: distinctsError,
    loading: distinctsLoading,
  } = useFetch(DISTINCTS_URL);

  const reset = () => {
    setFiltersString("");
    setQueryString("");
  };

  useEffect(() => {
    setUrl(`${URL}&offset=0&${filtersString}&${queryString}`);
    console.log("query: " + url);
  }, [filtersString, queryString]);

  useEffect(() => {
    setUrl(
      `${URL}&offset=${PAGE_SIZE * pageNo}&${filtersString}&${queryString}`
    );
    console.log("rendering page: " + pageNo);
  }, [pageNo]);

  useEffect(() => {
    rawData &&
      setData(
        rawData.data.sort((a, b) =>
          a[orderBy] > b[orderBy] ? sortDir : -sortDir
        )
      );
    rawData && setDataCount(rawData.count);
  }, [rawData, orderBy, sortDir]); // pageNo

  return (
    <div className="MNTable">
      <div className="MNTable-header">
        {distinctsLoading && (
          <Loading
            text={
              lang === "en"
                ? "Loading medical network"
                : "جاري تحميل الشبكة الطبية"
            }
          />
        )}
        {distincts && (
          <>
            <MNTableFilters
              distincts={distincts}
              setFiltersString={setFiltersString}
            />
            <SearchBar
              setQueryString={setQueryString}
              onChange={() => {
                queryString.replace(
                  /[^a-zA-Z0-9\u0621-\u064A\u0660-\u0669 @#%*]/g,
                  ""
                );
              }}
            />
            <Button variant="contained" disableElevation onClick={reset}>
              {lang === "en" ? "Reset" : "إعادة للأصل"}
            </Button>
          </>
        )}
      </div>
      {loading && (
        <Loading
          text={
            lang === "en"
              ? "Loading medical network"
              : "جاري تحميل الشبكة الطبية"
          }
        />
      )}
      {(error || distinctsError) && (
        <div>
          <br />
          <br />

          <p>
            {
              "Failed to load some or all data, please check your internet connection and refresh this page."
            }
          </p>
          <br />
          <p>
            If you believe this to be a bug, please contact
            bugs@omegacare-med.net
          </p>
          <br />
          <br />
          <br />
          <p dir="rtl">
            {
              "لم يتم تحميل بعض أو كل المعلومات، برجاء التأكد من أتصالك بالأنترنت ثم إعادة تحميل الصفحة."
            }
          </p>
          <br />
          <p dir="rtl">
            إن كنت متأكد من وجود مشكلة فالموقع، برجاء التواصل معنا على
            bugs@omegacare-med.net.
          </p>
          <br />
        </div>
      )}
      {data && data.length !== 0 && (
        <div className="table-container">
          <table
            style={{
              direction: lang === "en" ? "lrt" : "rtl",
            }}
          >
            <thead>
              {/* add on click to sory by clicked category */}
              <tr>
                <th
                  onClick={() => {
                    setOrderBy("governorate");
                    setSortDir(-sortDir);
                  }}
                >
                  {lang === "en" ? "Governorate" : "المحافظة"}
                </th>
                <th
                  onClick={() => {
                    setOrderBy("region");
                    setSortDir(-sortDir);
                  }}
                >
                  {lang === "en" ? "Region" : "المنطقة"}
                </th>
                <th
                  onClick={() => {
                    setOrderBy("service_provider");
                    setSortDir(-sortDir);
                  }}
                >
                  {lang === "en" ? "Service Provider" : "موفر لخدمة"}
                </th>
                <th
                  onClick={() => {
                    setOrderBy("branch_type");
                    setSortDir(-sortDir);
                  }}
                >
                  {lang === "en" ? "Branch Type" : "نوع الفرع"}
                </th>
                <th
                  onClick={() => {
                    setOrderBy("service_type");
                    setSortDir(-sortDir);
                  }}
                >
                  {lang === "en" ? "Service Type" : "نوع الخدمة"}
                </th>
                <th
                  onClick={() => {
                    setOrderBy("network_type");
                    setSortDir(-sortDir);
                  }}
                >
                  {lang === "en" ? "Network Type" : "نوع الشبكة"}
                </th>
                <th
                  onClick={() => {
                    setOrderBy("specialty");
                    setSortDir(-sortDir);
                  }}
                >
                  {lang === "en" ? "Specialty" : "التخصص"}
                </th>
                <th
                  onClick={() => {
                    setOrderBy("level");
                    setSortDir(-sortDir);
                  }}
                >
                  {lang === "en" ? "Level" : "المستوى"}
                </th>
                <th
                  onClick={() => {
                    setOrderBy("address");
                    setSortDir(-sortDir);
                  }}
                >
                  {lang === "en" ? "Address" : "العنوان"}
                </th>
                {/* <th>Email</th> */}
                <th
                  onClick={() => {
                    setOrderBy("phone");
                    setSortDir(-sortDir);
                  }}
                >
                  {lang === "en" ? "Phone" : "ارقام التيليفون"}
                </th>
                {/* <th>Phone 2</th> */}
                {/* <th>Notes</th> */}
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item) => (
                  <tr key={item._id}>
                    <td>{item.governorate}</td>
                    <td>{item.region}</td>
                    <td>{item.service_provider}</td>
                    <td>{item.branch_type}</td>
                    <td>{item.service_type}</td>
                    <td>{item.network_type}</td>
                    <td>{item.specialty}</td>
                    <td>{item.level}</td>
                    <td>{item.address}</td>
                    {/* <td>{item.email}</td> */}
                    <td>{item.phone}</td>
                    {/* <td>{item.phone2}</td> */}
                    {/* <td>{item.notes}</td> */}
                  </tr>
                ))}
            </tbody>
          </table>
          {/* add data navgation usting  */}
          <div
            className="pagination"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          ></div>
        </div>
      )}
      {(!data || data.length === 0) && (
        <p>{lang === "en" ? "No results available." : "لا نتائج متاحة."}</p>
      )}
      <div className="lower-table-section" dir="rtl">
        <Pagination
          size="small"
          count={Math.ceil(dataCount / PAGE_SIZE)}
          onChange={(e, value) => {
            setPageNo(value - 1);
          }}
          variant="outlined"
          dir="rtl"
          style={{ direction: "ltr" }}
        />
      </div>
    </div>
  );
}
