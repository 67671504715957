import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./HMenu.css";
import LanguageContext from "../contexts/LanguageContext";

import { Box, SwipeableDrawer, Button, List } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import LangSwitch from "./LangSwitch";

export default function HMenu({ setLanguage, links }) {
  const [isOn, setState] = useState(false);
  let lang = useContext(LanguageContext)
  const toggleDrawer = (event, open) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  const handleClick = (e) => {
    if (e.target.type !== "checkbox") toggleDrawer(e, false);
  };

  const content = () => (
    <Box
      className="test"
      sx={{ width: "60vw" }}
      role="presentation"
      onClick={(e) => handleClick(e)}
      // onClick={toggleDrawer(false)}
      // onKeyDown={toggleDrawer(false)}
    >
      <List className="links">
        {links[lang].map((link, i) => (
          <Link key={i} className="hmenu-link" to={links.links[i]}>
            {link}
          </Link>
        ))}
        <LangSwitch setLangauge={setLanguage}/>
      </List>
    </Box>
  );

  return (
    <div className="hmenu">
      <Button onClick={(e) => toggleDrawer(e, true)}>
        <MenuIcon style={{ color: "white" }} />
      </Button>
      <SwipeableDrawer
        anchor={lang === "en" ? "right" : "left"}
        open={isOn}
        onClose={(e) => toggleDrawer(e, false)}
        onOpen={(e) => toggleDrawer(e, true)}
      >
        {content()}
      </SwipeableDrawer>
    </div>
  );
}
